.app__navbar {
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 2;
}
.content-box {
  box-sizing: border-box;
  float: right;
  display: flex;
}

.still-text {
  font-family: "Poppins", sans-serif;
  color: var(--dd-grey);
  background-color: transparent;
  font-size: 14px;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.still-box {
  border-radius: 25px;
  display: inline-block;
  margin-right: 10px;
  padding-left: 7px;
  padding-right: 7px;
  position: relative;
}

.fixedNavbar {
  width: 100%;
  position: fixed;
  z-index: 2;
  grid-area: header;
  margin-top: -20px;
  justify-content: center;
}

.inline {
  display: block;
}

.route-menu {
  margin-left: 15px;
  margin-right: -3px;
}

.icon-link {
  border-radius: 2px;
  max-width: 25px;
  height: auto;
}

.un-underline {
  text-decoration: none;
  color: var(--d_grey);
  font-weight: 800;
}

.icon-pic {
  width: 50px;
  height: 50px;
  /* display: inline-block; */
}

.first-section {
  margin-bottom: 30px;
}
