@import "react-alice-carousel/lib/alice-carousel.css";
:lang(en) {
  font-family: "DM Sans", sans-serif;
  /* font-size: calc(60% + 0.8vmin); */
}

#FullName {
  font-family: "Yeseva One", cursive;
}

:lang(th) {
  font-family: "Noto Sans Thai", sans-serif;
  /* font-size: calc(60% + 0.8vmin); */
}

/* fading route */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200 ease-in-out;
}

:root {
  --grey: #acacac;
  --d_grey: #707070;
  --dd-grey: #393939;
  --white: #ffffff;
}

iframe {
  pointer-events: none;
}
